.publish-page {
  padding: 20px;
}
.label {
  margin-left: 10px;
}
.std-btn {
  margin: 10px !important;
}
.form-control {
  margin: 10px !important;
}
small {
  margin-right: 15px;
  opacity: 0.75;
}
.content-item-form {
  flex: 1 1 300px;
  max-width: 300px;
  margin: 10px;
  border: 1px solid #aaa;
  border-radius: 10px;
  display: flex;
flex-direction: column;
}
.content-wall-gallery {
  display: flex;
  flex-wrap: wrap;
}
a {
  color: #aaf;
}
